@import "../../../../style-config/variable.scss";
@import "../../../../style-config/mixins.scss";

.contacts-showcase {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -10px;
  height: 231px;

  .indi-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 261px;
    height: 277px;
    overflow: hidden;
    border-radius: 16px;
    margin: 10px;
    position: relative;
    cursor: pointer;
    background-color: white;

    transition: all 300ms;

    &:hover {
      background-color: #fed22b;
      transform: scale(1.05);
    }

    .meta-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      // padding: 50px;
      border-radius: 10px;

      img {
        width: 64px;
        height: 64px;
        margin-bottom: 20px;
        // color: black;
      }

      h3 {
        color: $black;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }
}
