@import "../../style-config/variable.scss";
@import "../../style-config/mixins.scss";

.footer {
  padding-bottom: 0;
}
.footer-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .footer-logo {
    width: 150px;
    img {
      display: block;
      width: 100%;
    }
  }
  .footer-menu-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-top: 30px;
    .footer-menu-item {
      @include typography(18px, 400, 22px);
      color: $white;
      margin: 0 12.5px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .main-container {
    margin: 0 auto;

    // max-width: 100%;
    padding: 2rem 1rem;

    .grid-wrapper {
      --auto-grid-min-size: 10rem;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(
        auto-fill,
        minmax(var(--auto-grid-min-size), 1fr)
      );
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;

      .grid-info {
        background-color: #2df8c5;
        border-radius: 15px;
        color: black;
        font-size: 16px;

        padding: 1rem 1rem;

        text-align: center;

        font-weight: 600;

        .center {
          /* display: block; */
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2rem;
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .social-icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 18px;
      margin: 0 12.5px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .bottom-bar {
    width: 100%;
    height: 75px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 75px;
    .copyright-text {
      color: #777777;
      @include typography(14px, 400, 22px);
    }
  }
}
