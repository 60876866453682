.Intro-section {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(121deg, #2559cd 9%, #1c4cc8 41%, #0f38c1 80%);
    position: relative;
    display: flex;
    justify-content: center;
    .vector-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: url("../../images/vector-background.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 0;
        opacity: 0.4;
    }
    .cloud {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }
    .cloud-soft {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        mix-blend-mode: soft-light;
    }
    .content {
        width: 1200px;
        padding: 0 20px;
        position: relative;
        display: flex;
        flex-direction: column;
    }
}
