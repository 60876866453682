@import "../../../style-config/variable.scss";
@import "../../../style-config/mixins.scss";

.intro-content {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  .layout {
    display: flex;
    .left-col {
      width: 45%;
      @include medium-screen {
        padding-top: 55px;
      }
      @include large-screen {
        padding-top: 0;
        width: 37%;
      }
      .title {
        display: flex;
        flex-direction: column;
        color: $white;
        .small-text {
          @include typography(42px, 400, 48px);
          display: flex;
          align-items: center;
          .icon {
            margin-left: 10px;
          }
        }

        .icon {
          animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
          animation-duration: 2.5s; /* Change to speed up or slow down */
          animation-iteration-count: infinite; /* Never stop waving :) */
          transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
          display: inline-block;

          @keyframes wave-animation {
            0% {
              transform: rotate(0deg);
            }
            10% {
              transform: rotate(14deg);
            } /* The following five values can be played with to make the waving more or less extreme */
            20% {
              transform: rotate(-8deg);
            }
            30% {
              transform: rotate(14deg);
            }
            40% {
              transform: rotate(-4deg);
            }
            50% {
              transform: rotate(10deg);
            }
            60% {
              transform: rotate(0deg);
            } /* Reset for the last half to pause */
            100% {
              transform: rotate(0deg);
            }
          }
        }
      }
      .typing-effect {
        color: $white;
        @include typography(20px, 400, 24px);
        margin: 25px 0;
        .typed-text {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: red;
          border-left: 2px solid #4070f4;
          animation: animate 4s steps(12) infinite;
        }
        @keyframes animate {
          40%,
          60% {
            left: 100%;
          }
          100% {
            left: 0%;
          }
        }
      }
      p {
        color: $white;
        @include typography(20px, 400, 24px);
        margin: 25px 0;
      }
    }
    .social-buttons {
      display: flex;
      justify-content: flex-start;
      gap: 25px;
    }

    .right-col {
      width: 55%;
      display: flex;
      align-items: flex-end;
      position: relative;
      @include large-screen {
        width: 63%;
      }
      img {
        width: 100%;
        display: block;
        margin-bottom: 40px;
        @include large-screen {
          margin-bottom: 50px;
          position: relative;
          right: -100px;
        }
      }
      .highlights {
        position: absolute;
        border-radius: 6px;
        @include glassmorphism-effect;
        .icon {
          width: 55px;
          height: 55px;
          min-width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $yellow;
          font-size: 24px;
          color: $black-light;
        }
        .text {
          padding: 0 10px;
          @include typography(18px, 400, 24px);
          color: $black-light;
          span {
            @include typography(18px, 400, 24px);
          }
        }

        &.horizontal {
          width: 208px;
          height: 95px;
          display: flex;
          align-items: center;
          top: 250px;
          left: 0;
          padding: 20px 10px 20px 20px;
          @include medium-screen {
            top: 250px;
            left: 0;
          }
          @include large-screen {
            top: 270px;
            left: 120px;
          }
        }
        &.verticle {
          width: 135px;
          height: 165px;
          min-height: 165px;
          display: flex;
          flex-direction: column;
          align-items: center;
          top: 50px;
          right: 60px;
          padding: 20px;
          padding-bottom: 10px;
          text-align: center;
          @include medium-screen {
            top: 50px;
            right: 60px;
          }
          @include large-screen {
            top: 85px;
            right: -10px;
          }
          .text {
            padding: 0 10px;
            @include typography(14px, 400, 18px);
            color: $black-light;
            margin-top: 10px;
            span {
              @include typography(18px, 700, 24px);
              display: block;
            }
          }
        }
      }
    }
  }
}
.text-animation {
  display: flex;
  @include typography(20px);
  margin-top: 5px;
}
.moving-text {
  margin-left: 5px;
}
