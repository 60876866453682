.individual-skills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.individual-skills-children {
  width: 136px;
  margin: 20px;
}
